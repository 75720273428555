<template>
  <page title="申请售后">
    <media-item
      :image="TestData.examines[parseInt($route.params.orderId)].image" 
      :title="TestData.examines[parseInt($route.params.orderId)].title"  
      img-ratio="16/15"
      img-width="80"
      note="数量：202台"
    >
    <div class="text-xs text-gray-light mt-1">订单编号：20202225522222</div>
    </media-item>
    <div class="mt-2 bg-white p-4">
      <div class="font-medium mb-4">退货申请</div>
      <cube-textarea v-model="value" placeholder="请输入退货申请内容"></cube-textarea>
      <!-- <textarea name="apply" cols="30" rows="5" class="w-full outline-none text-sm" placeholder="请输入退货申请内容"></textarea> -->
    </div>
    <div class="mt-2 bg-white p-4">
      <div class="font-medium mb-4">物流信息</div>
      <cube-input v-model="value1" class="mb-3" placeholder="物流公司"></cube-input>
      <cube-input v-model="value2"  placeholder="物流单号"></cube-input>
    </div>
    <btn color="primary" radius="none" slot="footer" @click="showAlert">提交申请</btn>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      value: '',
      value1: '',
      value2: '',
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    showAlert() {
      this.$createDialog({
        type: 'alert',
        title: '提示',
        content: '已经提交申请',
      }).show()
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>